<template>
  <validation-observer ref="formIndividual">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Lead Source"
            vid="Lead Source"
          >
            <b-form-group label="Lead Source" label-for="h-First">
              <b-form-select v-model="individual.source" :options="socialMedias" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            rules="required"
                  #default="{ errors }"
            name="Sales Person"
            vid="Sales Person"
          >
            <b-form-group label="Sales Person" label-for="h-First">
              <b-form-select
                v-model="individual.salesPersonId"
                :options="optionsSalesPerson"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Title"
            vid="Title"
          >
            <b-form-group label="Title" label-for="h-First">
              <b-form-select v-model="individual.title" :options="options" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <b-form-group label="First Name" label-for="h-First">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="First Name"
              vid="First Name"
            >
              <b-form-input
                id="h-First"
                placeholder="First Name"
                v-model="individual.fName"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Last Name" label-for="h-Last">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Last Name"
              vid="Last Name"
            >
              <b-form-input
                id="h-Last"
                placeholder="Last Name"
                v-model="individual.lName"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- email -->
        <b-col cols="12">
          <validation-provider
            rules="required|email"
            v-slot="{ errors }"
            name="Email"
            vid="Email"
          >
            <b-form-group label="Email" label-for="d-email">
              <b-form-input
                id="d-email"
                type="email"
                placeholder="Email"
                v-model="individual.email"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Address Line 1"
            vid="Address Line 1"
          >
            <b-form-group label="Address Line 1" label-for="h-Address">
              <b-form-input
                id="h-Address"
                placeholder="Address"
                v-model="individual.addressLine1"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Address Line 2"
            vid="Address Line 2"
          >
            <b-form-group label="Address Line 2" label-for="h-Address Line 2">
              <b-form-input
                id="h-Address Line 2"
                placeholder="Address Line 2"
                v-model="individual.addressLine2"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="City"
            vid="City"
          >
            <b-form-group label="City" label-for="h-City">
              <b-form-input id="h-City" placeholder="City" v-model="individual.city" />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            name="Telephone"
            vid="Telephone"
          >
            <b-form-group label="Telephone" label-for="h-Telephone">
              <b-form-input
                id="h-Telephone"
                placeholder="Telephone" type="number"  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                v-model="individual.tel"
              />
              <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Job Title" label-for="h-title">
            <b-form-input
              id="h-Title"
              placeholder="Job title"
              v-model="individual.jobTitle"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Products Interested in" label-for="h-title">
            <b-form-textarea
              id="event-description"
              placeholder="Products Interested in"
              v-model="individual.comments"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
    
  props: {
    individual: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: [
        { value: null, text: "Please select a title" },
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Ms", text: "Ms" },
      ],
      optionsSalesPerson: [],
      socialMedias: [
        { text: "Other", value: 0 },
        { text: "Facebook", value: 1 },
        { text: "Instagram", value: 2 },
        { text: "Twitter", value: 3 },
        { text: "LinkedIn", value: 4 },
      ],
      selected: "Select Social Media",
      ///////////////
      required,
      email,
    };
  },



  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },
  async mounted() {

    console.log('test ',this.individual);
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
  },
  methods: {
    saveSocialMedia(value, text) {
      this.individual.source = value;
      this.selected = text;
    },

    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
    },

    resetForm() {
      this.$refs.formIndividual.reset();
    },
    async submitForm(){
      let isValid = await this.$refs.formIndividual.validate();
      return isValid;
    }
  },
    watch: {
      individual: {
        handler(newVal) {
          //this.$refs.emit('generateLeadData', this.individual);
        }
      }
    },
};
</script>
