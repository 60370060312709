<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner variant="success" label="Spinning" style="width: 3rem; height: 3rem"></b-spinner>
    </div>

    <div v-if="!loadingSpinner">
      <form-wizard color="#B1D06C" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
        back-button-text="Previous" class="mb-3" @on-complete="formSubmitted">
        <!-- accoint details tab -->

        <tab-content title="Lead Details">
          <b-tabs pills v-on:input="getValue($event)">
            <b-tab title="Individual" active>
              <b-card-text>
                <b-card title="Add Individual">
                  <AddLead :individual="individual" ref="addLeadProfile"/>
                </b-card>
              </b-card-text>
            </b-tab>
            <b-tab title="Company">
              <b-card-text>
                <b-card title="Add Company">
                  <AddCompany :company="company" ref="addCompanyProfile" />
                </b-card>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTab,
  BCard,
  BCardText,
  BContainer,
  BSpinner,
  BTabs,
} from "bootstrap-vue";
import AddCompany from "./AddCompany.vue";
import AddLead from "./AddLead.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BCardText,
    TabContent,
    BRow,
    BContainer,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    AddCompany,
    AddLead,
    BTab,
    BCard,
    BTabs,
  },
  data() {
    return {

      loadingSpinner: true,
      individual: {
        source: null,
        lName: null,
        fName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        tel: null,
        title: null,
        jobTitle: null,
        comments: null,
        salesPersonId: null,
      },

      company: {
        source: null,
        companyName: null,
        otherName: null,
        email: null,
        brn: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        tel: null,
        website: null,
        vatNumber: null,
        comments: null,
        salesPersonId: null,
      },

      valueCheck: null,
    };
  },
  computed: {
    ...mapGetters("leadModule", {
      loading: "loading",
    }),
    otherName() {
      return this.individual.fName + " " + this.individual.lName;
    },
  },

  mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 2000);
  },
  methods: {
    ////////
    ...mapActions("leadModule", ["addLeadAction"]),

    getValue(value) {
      this.valueCheck = value;
    },

    
    async formSubmitted() {
      
      if (this.valueCheck == 0) {
          await this.addLeadsIndividual();
      }else{
        await this.addLeadsCompany();
      }
          
        },

    success(response) {
      this.$swal({
        title: "Leads!",
        text: "You have successfully added the lead!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            title: "Do you want a quotation?",
            showDenyButton: true,
            confirmButtonText: "YES",
            denyButtonText: "NO",
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(response.result)
              this.$router.push( "/quotation/add/" + response.result.id );
            } else if (result.isDenied) {
              this.$router.push({ name: "leads-list" });
            }
          });
        }
      });
    },

    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    async addLeadsIndividual() {
      let isValid = await this.$refs.addLeadProfile.submitForm();

      console.log(isValid, this.individual);      
      if(isValid){
        let payload = {
          otherName: this.otherName,
          email: this.individual.email,
          addressLine1: this.individual.addressLine1,
          addressLine2: this.individual.addressLine2,
          city: this.individual.city,
          tel: this.individual.tel,
          jobTitle: this.individual.jobTitle,
          title: this.individual.title,
          source: this.individual.source,
          comments: this.individual.comments,
          type: 0,
          status: 0,
          salesPersonId: this.individual.salesPersonId,
        };
        this.addLeadAction(payload)
          .then((response) => {
            this.success(response);
            this.$refs.addLeadProfile.resetForm();
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Form Submitted",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
          })
          .catch(() => {
            this.error();
          });
      }
    },

    async addLeadsCompany() {
      let isValid = await this.$refs.addCompanyProfile.submitForm();

      console.log(await this.$refs.addCompanyProfile.submitForm());
      
      console.log(isValid, this.company);      
      if(isValid){
      let payload = {
        otherName: this.company.otherName,
        email: this.company.email,
        companyName: this.company.companyName,
        addressLine1: this.company.addressLine1,
        addressLine2: this.company.addressLine2,
        city: this.company.city,
        tel: this.company.tel,
        website: this.company.website,
        brn: this.company.brn,
        vatNumber: this.company.vatNumber,
        source: this.company.source,
        comments: this.company.comments,
        salesPersonId: this.company.salesPersonId,
        type: 1,
        status: 0,
        isActive: true,

      };
      this.addLeadAction(payload)
        .then((response) => {
          this.success(response);
          
          this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Form Submitted",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
        })
        .catch(() => {
          this.error();
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.dropdown-toggle {
  text-align: left;
}
</style>
